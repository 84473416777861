<template>
    <div class="tenant-accounts-view ViewWrapper">
        <clv-head-meta :title="$t('Accounts')"></clv-head-meta>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <common-box-logo></common-box-logo>

                    <!-- BODY -->
                    <div class="gk-card gk-card--core-style-1 mb-5">
                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Accounts') }}</p>
                            <p class="mb-0 text-center text-muted extra-small" v-if="!accountTenantAccountListFetching">{{ tenantAccountsSize }} {{ $t(tenantAccountsWord) }}</p>
                        </div>

                        <!-- LOADER 2020-02-04 -->
                        <div class="ElementLoading-Placeholder" v-if="accountTenantAccountListFetching">
                            <element-loading :active="true" background-color="transparent"></element-loading>
                        </div>

                        <!-- EMPTY STATE -->
                        <div class="gk-card border-0" v-if="displayEmptyState">
                            <p class="mb-0 text-muted text-center weight-3">{{ $t('No Accounts Found') }}</p>
                            <p class="mb-4 text-muted text-center small">{{ $t('Create account or contact sales') }}</p>
                            <router-link tag="button" class="btn btn-block btn-accent1" :to="{ name: 'sign-up' }">{{ $t('Create Account') }}</router-link>
                        </div>

                        <!-- LISTING -->
                        <div v-if="displayListing">

                            <!-- TENANT ACCOUNT CARD (LISTING ITEMS) -->
                            <div
                                class="gk-card with-shadow-reactive-1 cursor-pointer"
                                v-for="tenantAccount in accountTenantAccountList"
                                :key="tenantAccount.tenantId"
                                @click="goToWebAppBase(tenantAccount.tenantId)"
                            >
                                <div class="d-flex justify-content-start align-items-center">
                                    <div>
                                        <p class="weight-3 size-5 mb-0" style="word-break: break-all;">{{ tenantAccount.name }}</p>
                                        <p class="small weight-3 mb-0" style="word-break: break-all;">
                                            {{ tenantAccount.tenantId }} / {{ tenantAccount.alias }}
                                        </p>
                                        <!--
                                        <span class="badge badge-style-1">Basic Plan</span>
                                        -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <common-box-links></common-box-links>
                    </div>

                    <common-box-footer></common-box-footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CLOUTLAYER_WEBAPP_URL } from '@/common/config/config';
import AccountAccessSharedMixin from './Mixin/AccountAccessSharedMixin';

export default {
    name: 'TenantAccountsView',
    mixins: [
        AccountAccessSharedMixin
    ],
    computed: {
        tenantAccountsSize () {
            return this.accountTenantAccountList.length;
        },
        tenantAccountsWord () {
            return this.accountTenantAccountList.length === 1 ? 'account' : 'accounts';
        },
        displayEmptyState () {
            if (this.accountTenantAccountListFetching) {
                return false;
            }
            return this.accountTenantAccountList.length === 0;
        },
        displayListing () {
            if (this.accountTenantAccountListFetching) {
                return false;
            }
            return this.accountTenantAccountList.length > 0;
        }
    },
    beforeMount () {
        // If is not authenticated, go to 'login'.
        this.goToRouteBasedOnAuth(false, 'login');

        // Check auth.
        this.checkAuthRemoteAsync();
    },
    methods: {
        goToWebAppBase (tenantId) {
            // Redirect to App.
            window.location.href = CLOUTLAYER_WEBAPP_URL + '/tenant/' + tenantId;
        }
    }
};
</script>
